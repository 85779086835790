import { Link } from 'gatsby'
import React from "react"
// import { useReduceMotion } from "react-reduce-motion";
import { useScrollAnimation } from "../components/useScrollAnimation";
import { motion, useTransform } from "framer-motion";
import Layout from '../components/Layout'
import Seo from '../components/seo'
import BannerMp4 from '../videos/about.mp4'
import BannerWebm from '../videos/about.webm'
import BannerOgv from '../videos/about.ogv'
import BannerCover from '../videos/video_cover_what.jpg'


export default function NotFound() {

    // const prefersReducedMotion = useReduceMotion();
    const prefersReducedMotion = false; //react-reduce-motion fails at build time due to window

    const [bannerRef, bannerScroll] = useScrollAnimation();
    const bannerScale = useTransform(bannerScroll, [0, 0.5, 1], prefersReducedMotion ? [1, 1, 1] : [1, 1, 1.2]);


    return (
        <Layout>


            <Seo
                title='404 Page Not Found | Webfox'
                description="We've looked everywhere but this page does not exist"
            />


            {/* section1 */}
            <div ref={bannerRef}>
                <section className="layer intro intro-default dark">
                    <motion.video animate={{ scale: bannerScale.get() }} id="background-video" autoPlay loop muted playsInline poster={BannerCover}>
                        <source src={BannerWebm} type='video/webm' />
                        <source src={BannerMp4} type='video/mp4' />
                        <source src={BannerOgv} type='video/ogg' />
                    </motion.video>

                    <div className="inner">
                        <div className="copy">
                            <h1>404 Page Not Found</h1>
                        </div>
                    </div>
                </section>
            </div>


            {/* section 2 */}


            <section className="layer double-text transparent not-found">
                <div className="inner">

                    <h2>We've looked everywhere but this page does not exist</h2>

                    <div className="button-group">
                        <Link to="/" className="button icon-right">Let us guide you back to the fox den.  <i className="mdi mdi-arrow-right"></i></Link>

                    </div>
                </div>
            </section>


        </Layout>
    )
}
